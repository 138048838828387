<template>
  <div :style="computedStyle" v-show="!isHidden" :class="CSSClasses" class="boolean_field">
    <el-form-item
      ref="formItem"
      :class="elFormCssClasses"
      :style="elFormCss"
      :prop="name"
      :rules="[
        { validator: requiredValidator, trigger: 'change' },
      ]"
    >
      <el-checkbox
        v-bind:value="!!stringToBoolean"
        v-on:input="change"
        :size="size"
        :disabled="_isReadonly"
        :name="name"
      >
        <span :class="{ show_asterisk: _isRequired && requiredStyle === 'asterisk' }">
          {{ label }}
        </span>
      </el-checkbox>

      <el-tooltip
        v-if="tooltip"
        style="margin-left: 5px"
        effect="dark"
        :content="tooltip"
      >
        <i class="el-icon-question"></i>
      </el-tooltip>
    </el-form-item>

    <slot></slot>
  </div>
</template>

<script>
import Vue from 'vue'
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import RequiredMixin from '@/components/InterfaceEditor/components/required_properties_mixin'
import InputLabel from '@/mixins/inputLabel.js'

export default Vue.extend({
  name: 'boolean_field',

  mixins: [mixin, registryMixin, RequiredMixin, InputLabel],

  props: {
    tooltip: {
      description: 'tooltip',
      type: String
    },

    label: {
      description: 'name',
      type: String
    }
  },

  computed: {
    stringToBoolean () {
      if (typeof this.value !== 'string') {
        return this.value
      }

      switch (this.value.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
          return true

        case 'false':
        case 'no':
        case '0':
          return false

        default:
          return this.value
      }
    },

    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },

  mounted () {
    if (this.validationNeeded) {
      this.$refs.formItem.form.validateField(this.name)
    }
  },

  methods: {
    requiredValidator (rule, value, callback) {
      if (value || (value === null && !this._isRequired) || !this._isRequired) {
        callback()
      } else {
        callback(this.$locale.main.message.required_field)
      }
    },

    change (event) {
      if (!event) {
        this.$emit('input', false)
      } else {
        this.$emit('input', true)
      }
    }
  }
})
</script>

<style scoped>
.boolean_field /deep/ .el-form-item__content {
  line-height: 0;
}

.boolean_field .el-form-item:first-child {
  margin: 0;
}
</style>
